<template>
  <v-card class="">
    <v-col cols="12" md="12">
      <component-layout
        :title="
          customer
            ? 'Notify To : ' + customer.first_name
            : 'Create Notification'
        "
      >
        <v-card-text class="px-0 py-0">
          <v-container class="py-0 pb-7">
            <v-row class="mt-10">
              <v-col cols="12" sm="12">
                <v-select
                  class="mb-5"
                  v-bind="basic"
                  :items="['mail', 'sms']"
                  label="Notify Via"
                  v-model="form.notify_via"
                />
                <div v-if="!customer">
                  <v-checkbox
                    v-model="form.all_customer"
                    label="All Customer"
                  ></v-checkbox>
                </div>
                <template v-if="form.notify_via == 'mail'">
                  <v-autocomplete
                    class="mb-5"
                    v-bind="basic"
                    label="To"
                    v-model="form.email"
                    :rules="[rules.required]"
                    :disabled="customer ? true : false"
                    :items="customers"
                    multiple
                    :search-input.sync="search"
                    item-text="full_name"
                    item-value="email"
                    small-chips
                    v-if="!form.all_customer"
                  />
                  <v-text-field
                    class="mb-5"
                    v-bind="basic"
                    label="Subject"
                    v-model="form.subject"
                    :rules="[rules.required]"
                  />
                  <!-- <v-file-input label="Attachment" prepend-icon="" /> -->
                  <fileUpload
                    class="mb-5"
                    v-bind="basic"
                    @file-upload-success="fileUploadSuccess"
                    @delete-file="deleteFile"
                  />
                  <p class="mt-5">Message Body</p>
                  <tiptap-vuetify
                    class="mb-5"
                    v-bind="basic"
                    v-model="form.content"
                    :extensions="extensions"
                    placeholder="Message Body"
                    :rules="[rules.required]"
                    min-height="200px"
                  />
                </template>
                <template v-if="form.notify_via == 'sms'">
                  <v-text-field
                    class="mb-5"
                    v-bind="basic"
                    v-model="form.mobile_number"
                    label="Mobile Number"
                    :rules="[rules.required, rules.number]"
                    v-if="!form.all_customer"
                  />
                  <v-textarea
                    class="mb-5"
                    v-bind="basic"
                    v-model="form.message"
                    label="Message"
                    :rules="[rules.required]"
                  />
                </template>
              </v-col>
            </v-row>

            <v-row class="mt-7">
              <v-spacer></v-spacer>
              <v-btn class="primary" @click="notify()" :loading="loading"
                >Send</v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </component-layout>
    </v-col>
  </v-card>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import util from "@/util/util";
import fileUpload from "./UploadFiles.vue";
// import PopUpLayout from "../../PopUpLayout.vue";
import componentLayout from "../../component-layout.vue";
import Rest from "@/components/shared/rest";
export default {
  props: {
    onClose: { type: Function },
    customer: {
      type: Object,
    },
  },
  components: { TiptapVuetify, fileUpload, componentLayout },
  data: () => ({
    // declare extensions you want to use
    loading: false,
    search: "",
    basic: util.input.basic,

    rules: util.validation,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    // starting editor's content
    content: "",
    form: {
      all_customer: false,
      notify_via: "mail",
      email: "",
    },
    attachments: [],
    customers: [],
    delete: true,
  }),
  mounted() {
    if (this.customer) {
      this.form.email = [this.$props.customer.email];
    } else {
      this.fetchCustomers();
    }
    //
  },
  methods: {
    notify() {
      this.form.attachments = [];
      // this.form.customer_id = this.$props.customer.id;
      for (var key in this.attachments) {
        this.form.attachments.push(this.attachments[key]);
      }
      this.loading = true;
      util
        .http({
          url: "notification/send-notification",
          method: "post",
          data: this.form,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.notify(1, "Notification Sent");
            this.delete = false;
            this.$emit("sent");
            this.form = {
              notify_via: "mail",
              email: this.$props.customer ? [this.$props.customer.email] : [],
              all_customer: false,
              content: "",
              subject: "",
              mobile_number: "",
              message: "",
            };
            this.form.email = this.$props.customer
              ? [this.$props.customer.email]
              : [];
            this.attachments = [];
          }
        });
    },
    fileUploadSuccess(file, res) {
      this.attachments[file.name] = res.data[0].url;
      this.delete = true;
    },
    deleteFile(file) {
      if (this.delete) {
        let url = this.attachments[file.name];
        util
          .http({
            url: "file-remove",
            method: "post",
            data: { url, name: file.name },
          })
          .then((res) => {
            if (res.data.status == 0) {
              util.notify(0, res.data.errors[0]);
            } else {
              delete this.attachments[file.name];
              util.notify(1, "Removed");
            }
          });
      }
    },
    fetchCustomers() {
      Rest.get.call(this, {
        resource: "customer",
        params: {
          q: this.search,
        },
      });
    },
  },
  computed: {
    actions() {
      return {
        login: {
          flat: true,
          text: "Login",
        },
      };
    },
  },
  watch: {
    search: function() {
      this.fetchCustomers();
    },
  },
};
</script>
<style scoped>
.card {
  padding: 10px;
}
</style>
