<template>
  <div>
    <vue-dropzone
      ref="myVueDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      :duplicateCheck="true"
      @vdropzone-success="fileUploadSuccess"
      @vdropzone-removed-file="fileRemoved"
    ></vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import util from "@/util/util";
export default {
  components: { vueDropzone: vue2Dropzone },
  props: {
    index: {
      required: false,
    },
  },
  data: () => ({
    dropzoneOptions: {
      url: util.baseUrl + "file-upload",
      thumbnailWidth: 150,
      maxFilesize: 0.5,
      addRemoveLinks: true,
      headers: { "My-Awesome-Header": "header value" },
      dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> Upload File",
    },
  }),
  methods: {
    fileUploadSuccess(file, res) {
      if (this.$props.index) {
        this.$emit("file-upload-success", file, res, this.$props.index);
      } else {
        this.$emit("file-upload-success", file, res);
      }
    },
    fileRemoved(file) {
      if (this.$props.index) {
        this.$emit("delete-file", file, this.$props.index);
      } else {
        this.$emit("delete-file", file);
      }
    },
  },
};
</script>
